.pad_21 {
    padding: 21px;
}

.block_left {
    float: left;
    padding-top: 11px;
    composes: pad_21;
}

.block_left_02 {
    width: 357px;
    float: left;
    padding-top: 10px;
}

.imgCon01 {
    position: relative;
    width: 358px;
    height: 174px;
    & img {
        width: 350px;
        height: 166px;
        border: #b8b8b8 1px solid;
        padding: 3px;
    }
    & .text {
        position: absolute;
        top: 145px;
        left: 19px;
        width: 330px;
        color: #fff;
        font: 16px/18px 微软雅黑;
        & a {
            color: #fff;
        }
    }
    & .textbg {
        position: absolute;
        bottom: 4px;
        left: 4px;
        width: 350px;
        height: 29px;
        background: #000;
        opacity: 0.7;
        filter: alpha(opacity=70);
    }
}
.imgCon02 {
    position: relative;
    width: 357px;
    height: 105px;
    margin-top: 15px;
    & img {
        width: 357px;
        height: 105px;
    }
    & .text {
        position: absolute;
        top: 80px;
        left: 19px;
        width: 330px;
        color: rgb(255, 255, 255);
        font: 16px/18px 微软雅黑;
        & a {
            color: #fff;
        }
    }
    & .textbg {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 357px;
        height: 29px;
        background: #000;
        opacity: 0.7;
        filter: alpha(opacity=70);
    }
}
