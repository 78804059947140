.block_left_03 {
    width: 583px;
    float: left;
    padding: 21px;
}
.dl_list_01 {
    & dl {
        padding-top: 22px;
        height: 131px;
        position: relative;
        background: url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_27.gif) repeat-x bottom;
    }
    & dt {
        width: 201px;
        float: left;
        & img {
            width: 180px;
            height: 104px;
        }
    }
    & dd {
        color: #8d8d8d;
        & h2 {
            font: normal 16px/16px '微软雅黑';
            height: 28px;
            line-height: 28px;
            color: #242424;
            overflow: hidden;
        }
        & .text {
            height: 58px;
            overflow: hidden;
            font: normal 12px/20px '微软雅黑';
        }
        & span {
            padding: 0 5px;
            border-left: #868686 1px solid;
            border-right: #868686 1px solid;
            height: 12px;
            overflow: hidden;
            text-align: right;
            position: absolute;
            top: 108px;
            right: 0px;
            line-height: 12px;
        }
    }
    & .links {
        width: 72px;
        height: 24px;
        text-align: center;
        display: block;
        background: url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_22.gif) no-repeat;
        color: #6f6f6f;
        font: normal 14px/24px '微软雅黑';
        position: absolute;
        top: 107px;
        left: 200px;
    }
}
