.slide_container {
    width: 604px;
    height: 297px;
    position: relative;
    & [ifeng_ui_pc_sliders-title] {
        width: 604px;
        height: 48px;
        /* line-height: 48px; */
        position: absolute;
        padding-top: 10px;
        top: 260px;
        color: #fff;
        font: normal 16px/18px '微软雅黑';
    }

    & [ifeng_ui_pc_sliders-link] :hover {
        text-decoration: underline;
        color: #fff;
    }
    & .dot {
        /* float: right; */
        position: relative;
        top: -20px;
        /* right: 50px; */
        width: 12px;
        height: 12px;
        background: url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_72.gif) no-repeat;
        margin: 0 5px;
        z-index: 1000;
    }
    & .current {
        position: relative;
        top: -20px;
        /* right: 50px; */
        background: url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_71.gif) no-repeat;
        color: #fff;
        width: 12px;
        height: 12px;
        margin: 0 5px;
        z-index: 1000;
    }

    & [ifeng_ui_pc_sliders-arrows_next] {
        position: absolute;
        top: 130px;
        width: 36px;
        height: 53px;
        opacity: 0.5;
        background: rgba(0, 0, 0, 0.5) url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_20.png) no-repeat
            center center;
    }

    & [ifeng_ui_pc_sliders-arrows_pre] {
        position: absolute;
        top: 130px;
        width: 36px;
        height: 53px;
        opacity: 0.5;
        background: rgba(0, 0, 0, 0.5) url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_19.png) no-repeat
            center center;
    }
}
