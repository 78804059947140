.content {
    width: 1024px;
    margin: 10px auto 0;
    position: relative;
    background: url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_08.gif) repeat-y center top;
}

.clear {
    clear: both;
    height: 6px;
    overflow: hidden;
}
.boxBot {
    height: 50px;
    background: url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_23.gif) no-repeat center bottom;
    clear: both;
}
