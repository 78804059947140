.topLogo {
    width: 100%;
    height: 158px;
    background: url(//y3.ifengimg.com/46da569a65fbd6a9/2015/0715/new_logo.gif) no-repeat center top;
    position: absolute;
    top: 0px;
    right: 0px;
}

.bodybg {
    background: url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_04.jpg) repeat-y center top;
    -webkit-text-size-adjust: none;
}

.footer {
    width: 1000px;
    margin: 0 auto;
    border-top: 1px solid #ccc;
}
