.affix_box {
    position: fixed;
    bottom: 50px;
    right: 0px;
    z-index: 999999;
}
.container {
    float: left;
}
.backtop {
    background: url(http://p0.ifengimg.com/9252e5c57d5b7a3b/2017/34/p-1.jpg);
    width: 48px;
    height: 85px;
}
.advisory {
    background: url(http://p0.ifengimg.com/9252e5c57d5b7a3b/2017/34/p-3.jpg);
    width: 48px;
    height: 76px;
}
.qrcode {
    background: url(http://p0.ifengimg.com/9252e5c57d5b7a3b/2017/34/p-4.jpg);
    width: 48px;
    height: 85px;
}
.qrcode_box {
    float: left;
    background: url(http://p0.ifengimg.com/9252e5c57d5b7a3b/2017/34/p-2.jpg);
    width: 339px;
    height: 245px;
    position: fixed;
    right: 48px;
    display: block;
}
.noshow {
    display: none;
}

.advisory_box {
    float: left;
    width: 339px;
    height: 245px;
    background: rgb(108, 108, 108);
    color: rgb(255, 255, 255);
    position: fixed;
    right: 48px;
    display: block;
}
.formItem {
    margin-left: 30px;
    margin-top: 25px;
}
.userName {
    margin-left: 20px;
    width: 200px;
    /* height: 20px; */
}
.company {
    margin-left: 32px;
    width: 200px;
    /* height: 20px; */
}
.purpose {
    margin-left: 30px;
    width: 200px;
}
.province {
    margin-left: 15px;
    width: 85px;
}
.city {
    margin-left: 25px;
    width: 85px;
}
.mobile {
    width: 165px;
}
.button {
    background: #ff7575;
    color: white;
    border: 1px solid transparent;
    border-radius: 4px;
    margin-left: 27px;
    width: 85px;
    cursor: pointer;
}
