.vtroll {
    height: 256px;
    position: relative;
    border-bottom: #dcdcdc 1px solid;
}
.title {
    height: 40px;
    font: normal 14px/40px '微软雅黑';
    padding-top: 10px;
    overflow: hidden;
}
.vask {
    width: 980px;
    position: absolute;
    overflow: hidden;
    & ul {
        width: 10000px;
        margin-top: 21px;
    }
    & li {
        width: 180px;
        float: left;
        padding-right: 20px;
        display: inline;
        position: relative;
        z-index: 1;
        color: #4f4f4f;
        font: normal 12px/18px '微软雅黑';
        cursor: pointer;
        & a {
            color: #4f4f4f;
        }
        & img {
            width: 180px;
            height: 135px;
        }
        & span {
            font: normal 10px/18px '微软雅黑';
            display: block;
            padding-left: 5px;
        }
    }

    & [ifeng_ui_pc_sliders-arrows_next] {
        position: absolute;
        width: 36px;
        height: 53px;
        top: 75px;
        cursor: pointer;
        z-index: 100;
        display: block;
        opacity: 0.5;
        background: rgba(0, 0, 0, 0.5) url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_20.png) no-repeat
            center center;
    }

    & [ifeng_ui_pc_sliders-arrows_pre] {
        position: absolute;
        width: 36px;
        height: 53px;
        top: 75px;
        cursor: pointer;
        z-index: 100;
        display: block;
        opacity: 0.5;
        background: rgba(0, 0, 0, 0.5) url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_19.png) no-repeat
            center center;
    }
}
