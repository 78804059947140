.picslide {
    width: 980px;
    height: 90px;
    position: relative;
    margin: 12px 0 40px 1px;
    & [ifeng_ui_pc_sliders-title] {
        display: none;
    }
}
.dot {
    /* float: right; */
    position: relative;
    top: 20px;
    right: 50px;
    width: 12px;
    height: 12px;
    background: url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_72.gif) no-repeat;
    margin: 0 5px;
}
.current {
    position: relative;
    top: 20px;
    right: 50px;
    background: url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_71.gif) no-repeat;
    color: #fff;
    width: 12px;
    height: 12px;
    margin: 0 5px;
}
