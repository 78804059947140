.block_item {
    background: url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_15.jpg) repeat-x;
    height: 34px;
    font: normal 18px/18px '微软雅黑';
    color: #842628;
    position: relative;
}
.ht {
    background: url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_16.jpg) repeat-x bottom;
    height: 3px;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 180px;
}
.ht_t {
    width: 51px;
    height: 17px;
    position: absolute;
    right: 0px;
    top: 8px;
    background: url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_17.gif) no-repeat;
}
