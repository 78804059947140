.menu {
    width: 1020px;
    margin: 0 auto;
    height: 148px;
    position: relative;
}

.menuList {
    position: absolute;
    left: 0px;
    top: 125px;
    /* background: url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_28.jpg) no-repeat -38px -8px; */
    & li {
        height: 23px;
        float: left;
        color: rgb(255, 255, 255);
        display: inline-block;
        position: relative;
        font: 14px/13px 微软雅黑;
        &:nth-last-child(n + 2) {
            background: url(//p0.ifengimg.com/fe/zl/test/live/application/list_01.3a0a562d.gif) no-repeat right center;
        }
    }
    & a {
        padding-left: 10px;
        display: inline-block;
        color: rgb(255, 255, 255);
        height: 23px;
        font: 14px/13px 微软雅黑;
        background: none;
        /* border-left:1px solid #fff; */
        &:hover {
            background: url(http://y3.ifengimg.com/b038735baea2cb5e/2013/0305/img_06.gif) no-repeat right bottom;
        }
        & span {
            padding-right: 17px;
            background: none;
            display: inline-block;
            line-height: 23px;
        }
    }
}
